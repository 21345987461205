export const textoAvisoPrivacidad = () => <>
    <p className="text-align-justify">
        Con fundamento en los artículos 15 y 16 de la Ley Federal de Protección de Datos Personales en Posesión de Particulares hacemos de su conocimiento que AUTOBUSES ESTRELLA BLANCA, S.A. de C.V. con domicilio en AVENIDA PONIENTE 140, NÚMERO 859, COLONIA INDUSTRIAL VALLEJO, DELEGACIÓN AZCAPOTZALCO C.P. 02300, es responsable de recabar sus datos personales, del uso que se le dé a los mismos y de su protección.
        <br /><br />
        Su información personal será utilizada para las siguientes finalidades: proveer los servicios que ha solicitado; notificarle sobre nuestros servicios o productos que tengan relación con los ya contratados o adquiridos; comunicarle sobre cambios en los mismos; elaborar estudios y programas que son necesarios para determinar hábitos de consumo; realizar evaluaciones periódicas de nuestros servicios a efecto de mejorar nuestra calidad de los mismos; evaluar la calidad del servicio que brindamos, y en general, para dar cumplimiento a las obligaciones que hemos contraído con Usted.
    </p>
    <br />
    <p className="text-align-justify">
        Para las finalidades antes mencionadas, requerimos obtener los siguientes datos personales:
    </p>
    <ul style={{ marginLeft: 15 }}>
        <li>Nombre completo</li>
        <li>Teléfono fijo y/o celular</li>
        <li>Correo electrónico</li>
        <li>Firma autógrafa</li>
        <li>Dirección</li>
        <li>RFC y/o CURP</li>
    </ul>
    <br />
    <p className="text-align-justify">
        Con base en la Ley de protección de datos personales en posesión de particulares, los siguientes datos son considerados sensibles:
    </p>
    <ul style={{ marginLeft: 15 }}>
        <li>Dirección</li>
        <li>RFC y/o CURP</li>
        <li>Teléfono fijo y/o celular</li>
        <li>Correo electrónico</li>
        <li>Firma autógrafa</li>
    </ul>
    <br />
    <p className="text-align-justify">
        Es importante informarle que Usted tiene derecho al Acceso, Rectificación y Cancelación de sus datos personales, a oponerse al tratamiento de los mismos o a revocar el consentimiento que para dicho fin nos haya otorgado. Para ello, es necesario que acuda a nuestra oficina donde realizó su compra o al call center correspondiente a los teléfonos: los Senderos: 01(55) 53282440 y 01(55) 53282434 y para Paquetería y Envía: 01(55) 57295107. A partir del 6 de enero de 2012, Usted podrá ejercer cualquiera de los derechos ARCO (Acceso, Rectificación, Cancelación u Oposición) acudiendo a nuestras oficinas. Cualquier cambio sustancial o total que se realice será comunicado mediante nuestro sitio web.
    </p>
</>