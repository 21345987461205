import { useState } from "react";

export const useModal = () => {
    const [isShowModalConfirmar, setShowModalConfirmar] = useState(false);
    const [titulo, setTitulo] = useState("");
    const [descripcion, setDescripcion] = useState("");
    const [mensajeImportante, setMensajeImportante] = useState("");
    const [handleConfirm, setHandleConfirm] = useState(() => () => {});
    const [isMostrarAceptar, setIsMostrarAceptar] = useState(true);
    const showModalConfirmar = () => {
        if (isShowModalConfirmar) {
            setMensajes("", "", "");
        }
        setShowModalConfirmar(!isShowModalConfirmar);
    }
    const setMensajes = (titulo, descripcion, mensajeImportante) => {
        setTitulo(titulo);
        setDescripcion(descripcion);
        setMensajeImportante(mensajeImportante)
    }
    const setHandleConfirmar = (handleConfirm) => {
        setHandleConfirm(() => (data) => handleConfirm(data))
    }
    return {
        // Atributos
        isShowModalConfirmar, handleConfirm,
        titulo, descripcion, mensajeImportante,
        isMostrarAceptar,
        // Métodos
        showModalConfirmar,
        setMensajes,
        setHandleConfirmar,
        setIsMostrarAceptar,
    }
}