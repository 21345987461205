import React, { useState, useEffect } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { fetchUsoCfdi, setUsoCfdi, fetchRegimenFiscal, stepsState, setFacturaInfo, fetchPreFactura, setFacturaBody, setRegistroBody, startLoading, endLoading } from '../../reducers/stepsSlice';
import { getReceptor } from '../../services/main';
import { IconBuscar, IconInfo } from './icon';

const usoCfdiName = 'usoCfdi';
const regimenFiscalName = 'regimenFiscal'

const mexFormInitialState = {
    usoCfdi: undefined,
    nombreRazonSocial: '',
    colonia: '',
    noInterior: '',
    municipio: '',
    pais: '',
    rfc: '',
    calle: '',
    noExterior: '',
    codigoPostal: '',
    estado: '',
    email: ''
}

const extFormInitialState = {
    usoCfdiExt: undefined,
    rfcPassaporte: '',
    razonSocial: '',
    emailExt: '',
}

export function ModalInformacion(props) {

    /**
    * Step Handle
    */
    const dispatch = useDispatch();
    const stepsData = useSelector(stepsState);

    /**
    * View Handle
    */
    const [nacionalidad, setNacionalidad] = useState('mex');
    const [showRegistro, setShowRegistro] = useState(false);

    /**
    * Form inputs
    */
    const [formValid, setFormValid] = useState(false);

    const [mexForm, setMexForm] = useState(mexFormInitialState);

    const [extForm, setExtForm] = useState(extFormInitialState);

    const [registroForm, setRegistroForm] = useState({
        nombreRegistro: '',
        telefonoRegistro: '',
        correoRegistro: '',
        codigoPostalRegistro: '',
    });
    
    const { rfc, regimenFiscal } = mexForm;
    /**
    * Change nacionality and resets form
    */
    const changeNacionalidad = (event) => {
        setFormValid(false);
        setNacionalidad(event.target.value)
        if(event.target.value === 'mex'){
            setExtForm(extFormInitialState);
        }else{
            setMexForm(mexFormInitialState);
        }
    }

    /**
    * Handle the validation for an input depenending the input the validation change
    */
    const validarRFC = (valor) => {
        const exp = /^([A-ZÑ&]{3,4}) ?(?:- ?)?(\d{2}(?:0[1-9]|1[0-2])(?:0[1-9]|[12]\d|3[01])) ?(?:- ?)?([A-Z\d]{2})([A\d])$/;
        const regex = new RegExp(exp);
        return regex.test(valor);
    }
    const validarEmail = (valor) => {
        const exp = "^[A-Za-z]+([\.-]?\\w+)*@\\w+([\\.-]?\\w+)*(\\.\\w{2,5})+$";
        const regex = new RegExp(exp);
        return regex.test(valor);
    }
    const validarTelefono = (valor) => {
        const exp = "^[0-9]{10}$";
        const regex = new RegExp(exp);
        return regex.test(valor);
    }
    const validarCP = (valor) => {
        const exp = "^\\d{5}$";
        const regex = new RegExp(exp);
        return regex.test(valor);
    }

    /**
    * Run the validations for all the current inputs
    */
    const validateAllInputs = () => {        
        const formInputs = nacionalidad === 'mex' ? 'mex-input' : 'ext-input';
        const inputs = document.getElementsByClassName(formInputs);
        const registerInputs = document.getElementsByClassName('reg-input');
        const inputsArray = Array.from(inputs);
        const registerInputsArray = Array.from(registerInputs);
        let inputsValid = false;

        for (let input of inputsArray) {
            if(input.value) {
                inputsValid = true;
                break;
            }
        }

        if(showRegistro && !inputsValid) {
            for (let input of registerInputsArray) {            
                if(input.value){
                    inputsValid = true;
                    break;
                }            
            }
        }

        setFormValid(inputsValid);
    }

    /**
    * Handle input change, checks the current form and validate if all inputs are fill to continue
    */
    const handleChange = (event) => {
        const { target } = event
        if(target.type !== 'checkbox') {
            if(!target.classList.contains('reg-input')){
                if(nacionalidad === 'mex'){
                    const data = { ...mexForm };
                    // Se establece en vacío el uso cfdi si cambia el regimen fiscal
                    if (target.id === regimenFiscalName) {
                        data[usoCfdiName] = '';
                        data[target.id] = target.value;
                    } else {
                        const camposValidar = [
                            "calle", "noExterior", "noInterior", "colonia", "municipio", "estado", "pais"
                        ];
                        const find = camposValidar.find(c => c === target.id);
                        if (find) {
                            let bandera = false;
                            for (let v of target.value) {
                                const caracteresNoPermitidos = ["<", ">", '"', '@', "'","|"];
                                if (caracteresNoPermitidos.find(c => c === v)) {
                                    bandera = true;
                                    break;
                                }
                            }
                            if (!bandera) {
                                data[target.id] = target.value;
                            }
                        } else {
                            data[target.id] = target.value;
                        }
                    }
                    setMexForm(data);
                }else{
                    setExtForm({
                        ...extForm,
                        [target.id]: target.value
                    })
                }
            }else{
                setRegistroForm({
                    ...registroForm,
                    [target.id]: target.value
                })
            }
        }
        validateAllInputs();
    }

    const handleOnBlurRFC = e => {
        if(!validarRFC(e.target.value)) {
            props.showErrorModal('error', 'El RFC del receptor tiene un formato inválido');
            return;
        }
        if (e.target.value.length === 12 || e.target.value.length === 13) {
            setMexForm({...mexForm, [regimenFiscalName]: '', [usoCfdiName]: '' });
            dispatch(fetchRegimenFiscal(encodeURIComponent(e.target.value)));
            dispatch(setUsoCfdi());
        } else {
            dispatch(fetchRegimenFiscal(''));
        }
    }

    /**
    * Search the receptor and if we get a response, we populate the data
    */
    const searchReceptor = () => {
        // Validaciones antes de de realizar el request
        if (!mexForm.rfc) {
            props.showErrorModal('error', 'El RFC del receptor es requerido');
            return;
        }
        if(!validarRFC(mexForm.rfc)) {
            props.showErrorModal('error', 'El RFC del receptor tiene un formato inválido');
            return;
        }
        dispatch(startLoading());
        getReceptor(encodeURIComponent(mexForm.rfc))
        .then(async resp => {
            const data = await resp.json();
            if(data.isSuccess){
                setMexForm({
                    rfc: data.data.RfcReceptor || '',
                    nombreRazonSocial: data.data.RazonSocialReceptor || '',
                    usoCfdi: mexForm.usoCFDI,
                    regimenFiscal: data.data.RegimenFiscalReceptor,
                    calle: data.data.CalleReceptor || '',
                    colonia: data.data.ColoniaReceptor || '',
                    noExterior: data.data.NumExtReceptor || '',
                    noInterior: data.data.NumIntReceptor || '',
                    codigoPostal: data.data.CodigoPostalReceptor || '',                    
                    municipio: data.data.MunicipioReceptor || '',
                    estado: data.data.EntidadReceptor || '',
                    pais: data.data.PaisReceptor || '',
                    email: ''
                })
            }else{
                props.showErrorModal('error', data.error);
            }
            dispatch(endLoading());
        }).catch(error => {
            dispatch(endLoading());
            props.showErrorModal('error');
        })
    }

    /**
     * Validate requiered form fields     
     */
    const validateForm = (body) => {
        //Uso del CFDI
        
        if (body.nacional === 1) {
            //RFC receptor
            if(!body.rfcReceptor)
                return 'El RFC del receptor es requerido';
            else if(!validarRFC(body.rfcReceptor))
                return 'El RFC del receptor tiene un formato inválido';            
            //Razón social Receptor
            if(!body.razonSocialReceptor)  
                return 'El nombre o razón social del receptor es requerido';            
            if (!body.regimenFiscal) { 
                return 'El Régimen Fiscal es requerido';
            }
            if (!body.usoCFDI) { 
                return 'El Uso del CFDI es requerido';
            }          
            //Codigo postal receptor
            if(!body.codigoPostalReceptor) 
                return 'El código postal del receptor es requerido';
            else if(!validarCP(body.codigoPostalReceptor))
                return 'El código postal del receptor tiene un formato inválido';   
        } else {
            //Número de pasaporte
            if (!body.numeroPasaporteReceptor)
                return 'El número de pasaporte es requerido';
            else 
                if (!body.razonSocialReceptor) {
                    return 'La razón social del receptor es requerido';
                } else 
                    if (!body.usoCFDI) { 
                        return 'El Uso del CFDI es requerido';
                    }
            
        }
        //Correo receptor
        if(!body.correoReceptor)
            return 'El correo electrónico del receptor es requerido';
        else if(!validarEmail(body.correoReceptor))
            return 'El correo electrónico del receptor tiene un formato inválido';
        
        //Forma de registro
        if (showRegistro) {
            //Nombre contacto
            if (!body.nombreContacto)
                return 'El nombre de contacto es requerido';            
            //Correo contacto
            if(!body.correoContacto)
                return 'El correo electrónico de contacto es requerido';
            else if(!validarEmail(body.correoContacto))
                    return 'El correo electrónico del contacto tiene un formato inválido';
            //Telefono contacto
            if(!body.telefonoContacto) 
                return 'El teléfono de contacto es requerido';
            else if(!validarTelefono(body.telefonoContacto))
                return 'El teléfono del contacto tiene un formato inválido';
            //Codigo postal contacto
            if(!body.codigoPostalContacto)
                return 'El código postal de contacto es requerido';
            else if(!validarCP(body.codigoPostalContacto))
                return 'El código postal del contacto tiene un formato inválido';
        }    
    }
   
    /**
    * Submit form to create the pre-invoice
    * we also set factura information for keep in the state
    */
    const handleSubmit = () => {
        const information = nacionalidad === 'mex' ? mexForm : extForm;
        const ticketBody = []
        stepsData.ticketsSelected.forEach(ticket => {
            ticketBody.push({
                IdTicket: ticket.IdTicket,
                Folio: ticket.Folio,
                FechaVenta: ticket.FechaVenta,
                Total: ticket.Total
            })
        })
        let body;
        const usoCFDI = stepsData.usoCfdi.find(u => u.Clave.toString() === (mexForm.usoCfdi && mexForm.usoCfdi.toString()));
        const regimenFiscal = stepsData.regimenFiscal && stepsData.regimenFiscal.find(u => u.IdRegimenFiscal.toString() === (mexForm.regimenFiscal && mexForm.regimenFiscal.toString()));
        const normalBody = {
            idEmpresa: 'GEB',
            isGenerarCFDI: '0',
            nacional: nacionalidad === 'mex' ? 1 : 0,
            usoCFDI: nacionalidad === 'mex' 
                ? mexForm.usoCfdi 
                : 'S01',
            descUsoCFDI: nacionalidad === 'mex' 
                ? usoCFDI 
                    ? usoCFDI.Descripcion : mexForm.usoCfdi 
                : 'Sin efectos fiscales',
            regimenFiscal: nacionalidad === 'mex' 
                ? regimenFiscal 
                    ? regimenFiscal.Clave : '' 
                : null,
            descRegimenFiscal: nacionalidad === 'mex' 
                ? regimenFiscal 
                    ? regimenFiscal.Descripcion : '' 
                : null,
            rfcReceptor: mexForm.rfc,
            razonSocialReceptor: nacionalidad === 'mex' ? mexForm.nombreRazonSocial : extForm.razonSocial,
            calleReceptor: mexForm.calle,
            coloniaReceptor: mexForm.colonia,
            numExtReceptor: mexForm.noExterior,
            numIntReceptor: mexForm.noInterior,
            codigoPostalReceptor: mexForm.codigoPostal,
            municipioReceptor: mexForm.municipio,
            entidadReceptor : mexForm.estado,
            paisReceptor: mexForm.pais,
            numeroPasaporteReceptor: extForm.passaporte,
            correoReceptor: nacionalidad === 'mex' ? mexForm.email : extForm.emailExt,
            tickets: ticketBody
        }
        const registerBody = {
            nombreContacto: registroForm.nombreRegistro,
            correoContacto: registroForm.correoRegistro,
            telefonoContacto: registroForm.telefonoRegistro,
            codigoPostalContacto: registroForm.codigoPostalRegistro
        }
        if(showRegistro){
            body = {...normalBody, ...registerBody};
        }else{
            body = normalBody;
        }
        
        //Se validan los campos capturados
        let errorValidacion = validateForm(body);

        if (errorValidacion) {
            props.showErrorModal('error', errorValidacion);
        } else {
            dispatch(fetchPreFactura(body)).then(resp => {
                if(Array.isArray(resp.payload.data) && resp.payload.data.length > 0){
                    dispatch(setFacturaInfo(information));
                    dispatch(setRegistroBody(registroForm));
                    body.isGenerarCFDI = '1';
                    dispatch(setFacturaBody(body));
                    props.handleStep("datosFiscales");
                } else {
                    props.showErrorModal('error',resp.payload.error.DetalleError);
                }
            })
        }
    }

    /**
    * React Hook
    */
    useEffect(() => {
        if(stepsData.facturaInformation){
            if(nacionalidad === 'mex'){
                setMexForm(stepsData.facturaInformation);
            }else{
                setNacionalidad('ext')
                setExtForm(stepsData.facturaInformation)
            }
        }
        if(stepsData.registroBody){
            let emptyRegister = true;
            for (const property in stepsData.registroBody) {
                if(stepsData.registroBody[property] !== ''){
                    emptyRegister = false;
                }
            }
            if(!emptyRegister){
                setShowRegistro(true);
                setRegistroForm(stepsData.registroBody);
            } 
        }
    }, [stepsData.registroBody, nacionalidad, dispatch, stepsData.facturaInformation])

    useEffect(() => {
        validateAllInputs()
    }, [showRegistro, validateAllInputs])

    useEffect(() => {
        if (mexForm.regimenFiscal) {
            dispatch(fetchUsoCfdi({rfc: encodeURIComponent(rfc), regimenFiscal}));
        } else dispatch(setUsoCfdi());
    }, [regimenFiscal])

    const handleClickInfo = (mensaje) => {
        props.showErrorModal('info', mensaje, 'Información');
    }

    return(
        <div className="modal">
            <div className="head">
                <h4>Información de Facturación</h4>
                <span onClick={props.handleModal}>
                    <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="bus" />
                </span>
            </div>
            <div className="content">
                <div className="top">
                    <div className="radio-wrapper">
                        <label>Nacionalidad</label>
                        <div className="radios">
                            <div className="radio">
                                <label>Mexicano</label>
                                <input checked={nacionalidad === "mex"} value="mex" name="nacionalidad" onChange={(e) => changeNacionalidad(e)} type="radio" />
                            </div>
                            <div className="radio">
                                <label>Extranjero</label>
                                <input checked={nacionalidad === "ext"} value="ext" name="nacionalidad" onChange={(e) => changeNacionalidad(e)} type="radio" />
                            </div>
                        </div>
                    </div>
                    <img src={`${process.env.PUBLIC_URL}/assets/facturacion.svg`} alt="facturacion" />
                </div>
                <div className="form-wrapper">
                    {nacionalidad === "mex" && 
                        <form id="mexForm" className="nacionalidad">
                            <div className='one-col'>
                                <label>Estimado cliente, le recordamos que los datos fiscales deben cumplir con las nuevas disposiciones del SAT. </label>
                                {/* <label>Para mayor información dar clic <a href="https://www.sat.gob.mx/cs/Satellite?blobcol=urldata&blobkey=id&blobtable=MungoBlobs&blobwhere=1579314891446&ssbinary=true" target='_blank'>aquí</a></label> */}
                                <p></p>
                            </div>
                            <div className="two-col">
                                <div className="input-wrapper">
                                    <input value={mexForm.rfc || ''} className="mex-input" onBlur={handleOnBlurRFC} onChange={(e) => handleChange(e)} id="rfc" name="rfc" type="text" maxLength={13} placeholder="*RFC"/>
                                    <button 
                                        type="button" 
                                        onClick={searchReceptor} 
                                        onMouseDown={searchReceptor}
                                        title="Da clic para buscar el RFC"
                                    >
                                        <IconBuscar />
                                    </button>
                                </div>
                                <div className="input-wrapper">
                                    <input value={mexForm.nombreRazonSocial || ''} className="mex-input" onChange={(e) => handleChange(e)} id="nombreRazonSocial" name="nombre" type="text" maxLength={200} placeholder="*Nombre o Razón Social"/>
                                    <button onClick={() => handleClickInfo("Nombre  o  razón  social  (No  debes  incluir  la  abreviación  S.A  de  C.V  o  cualquier  otra)")} type="button">
                                        <IconInfo />
                                    </button>
                                </div>
                                <div className="input-wrapper">
                                    <select value={mexForm.regimenFiscal || ''} className="mex-input" onChange={(e) => handleChange(e)} name={regimenFiscalName} id={regimenFiscalName} placeholder="*Régimen Fiscal">
                                        <option value="">*Régimen Fiscal</option>
                                        {stepsData.regimenFiscal && stepsData.regimenFiscal.length > 0 && 
                                            stepsData.regimenFiscal.map(regimen => {
                                                return <option key={regimen.IdRegimenFiscal} value={regimen.IdRegimenFiscal}>{regimen.Clave} - {regimen.Descripcion}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="input-wrapper">
                                    <select value={mexForm.usoCfdi || ''} className="mex-input" onChange={(e) => handleChange(e)} name={usoCfdiName} id={usoCfdiName} placeholder="*Uso de CFDI">
                                        <option value="">*Uso de CFDI</option>
                                        {stepsData.usoCfdi && stepsData.usoCfdi.length > 0 && 
                                            stepsData.usoCfdi.map(uso => {
                                                return <option key={uso.Clave} value={uso.Clave}>{uso.Clave} - {uso.Descripcion}</option>
                                            })
                                        }
                                    </select>
                                </div>
                                <div className="input-wrapper">
                                    <input value={mexForm.calle || ''} className="mex-input" onChange={(e) => handleChange(e)}  id="calle" name="calle" type="text" maxLength={100} placeholder="Calle"/>
                                </div>
                                <div className="input-wrapper">
                                    <input value={mexForm.noExterior || ''} className="mex-input" onChange={(e) => handleChange(e)}  id="noExterior" name="noExterior" type="text" maxLength={50} placeholder="No. Exterior"/>
                                </div>
                                <div className="input-wrapper">
                                    <input value={mexForm.noInterior || ''} className="mex-input" onChange={(e) => handleChange(e)}  id="noInterior" name="noInterior" type="text" maxLength={50} placeholder="No. Interior"/>
                                </div>
                                <div className="input-wrapper">
                                    <input value={mexForm.colonia || ''} className="mex-input" onChange={(e) => handleChange(e)}  id="colonia" name="colonia" type="text" maxLength={100} placeholder="Colonia"/>
                                </div>                                
                                <div className="input-wrapper">
                                    <input value={mexForm.codigoPostal || ''} className="mex-input" onChange={(e) => handleChange(e)} id="codigoPostal" name="codigoPostal" type="text" maxLength={5} placeholder="*Código Postal" pattern="[0-9]{0,5}" />
                                    <button type="button" onClick={() => handleClickInfo("Valida que tu código postal sea el mismo de tu constancia de situación fiscal.")}>
                                        <IconInfo />
                                    </button>
                                </div>
                                <div className="input-wrapper">
                                    <input value={mexForm.municipio || ''} className="mex-input" onChange={(e) => handleChange(e)}  id="municipio" name="municipio" type="text" maxLength={100} placeholder="Municipio"/>
                                </div>
                                <div className="input-wrapper">
                                    <input value={mexForm.estado || ''} className="mex-input" onChange={(e) => handleChange(e)}  id="estado" name="estado" type="text" maxLength={100} placeholder="Estado"/>
                                </div>
                                <div className="input-wrapper">
                                    <input value={mexForm.pais || ''} className="mex-input" onChange={(e) => handleChange(e)}  id="pais" name="pais" type="text" maxLength={100} placeholder="País"/>
                                </div>
                                <div className="input-wrapper">
                                    <input value={mexForm.email || ''} className="mex-input" onChange={(e) => handleChange(e)} id="email" name="email" type="text" maxLength={100} placeholder="*Correo electrónico"/>
                                </div>
                            </div>
                        </form>
                    }
                    {nacionalidad === "ext" && 
                        <div className="nacionalidad">
                            <div className="two-col">
                                <div className="input-wrapper">
                                    <input value={extForm.passaporte || ''} className="ext-input" onChange={(e) => handleChange(e)} id="passaporte" type="text" maxLength={50} placeholder="*Número de pasaporte"/>
                                </div>
                                <div className="input-wrapper">
                                    <input value={extForm.razonSocial || ''} className="ext-input" onChange={(e) => handleChange(e)} id="razonSocial" type="text" maxLength={100} placeholder="*Rázon social"/>
                                </div>
                                <div className="input-wrapper">
                                    <input value={extForm.emailExt || ''} className="ext-input" onChange={(e) => handleChange(e)} id="emailExt" type="text" maxLength={100} placeholder="*Correo electrónico"/>
                                </div>
                            </div>
                        </div>
                    }
                    {/* <div className="registro-disclaimer">
                        <img src={`${process.env.PUBLIC_URL}/assets/registro.svg`} alt="registro" />
                        <p>Compártenos tus datos para enterarte de las <br /> <b>promociones exclusivas</b> que tenemos para ti. <br /> Te tomará menos de 1 minuto.</p>
                    </div> */}

                </div>
                <div className="action flex">
                    {/* <button className="ternary" onClick={handleShowRegistro}>{!showRegistro ? 'Registrarme' : 'No Deseo Registrarme'}</button> */}
                    {!showRegistro && 
                        <button 
                            disabled={!formValid} 
                            type="submit" 
                            onClick={handleSubmit} 
                            className="ternary-second"
                        >
                            Facturar
                        </button>    
                    }
                </div> 
                {showRegistro && 
                    <div className="registro">
                        <div className="two-col">
                            <div className="input-wrapper">
                                <input value={registroForm.nombreRegistro || ''} className="reg-input" onChange={(e) => handleChange(e)} id="nombreRegistro" type="text" placeholder="*Nombre"/>
                            </div>
                            <div className="input-wrapper">
                                <input value={registroForm.correoRegistro || ''}  className="reg-input" onChange={(e) => handleChange(e)} id="correoRegistro" type="text" placeholder="*Correo electrónico"/>
                            </div>
                            <div className="input-wrapper">
                                <input value={registroForm.telefonoRegistro || ''}  className="reg-input" onChange={(e) => handleChange(e)} id="telefonoRegistro" type="text" placeholder="*Teléfono (10 dígitos)"/>
                            </div>
                            <div className="input-wrapper">
                                <input value={registroForm.codigoPostalRegistro || ''}  className="reg-input" onChange={(e) => handleChange(e)} id="codigoPostalRegistro" type="text" maxLength={5} placeholder="*Código Postal" pattern="[0-9]{0,5}" />
                            </div>
                        </div> 
                        <div className="action">
                            <button disabled={!formValid}  className="primary" onClick={handleSubmit}>Enviar</button>    
                        </div>
                    </div>
                }
            </div>
        </div>
    )
}