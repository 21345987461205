import React from 'react';
import { useSelector } from 'react-redux';
import { selectBrand } from '../reducers/brandingSlice';
import { constants } from './constants';


export function ModalPasos(props) {
  /**
   * Brand/Theming 
   */
  const brand = useSelector(selectBrand);

  return (
    <div className="modal steps">
      <div className="head">
        <h4>Pasos para realizar una factura</h4>
        <span onClick={props.showExamples}>
          <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="close" />
        </span>
      </div>
      <div className="content">
        <div className="step">
          <div className="step-head">
            <h3>Paso 1</h3>
            <p>Ingresa los datos que se encuentran en tu {brand === "envia" ? "guía" : "boleto"}.</p>
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/assets/comofacturar/paso1_1.jpg`}
            alt="example"
            style={{ marginTop: -20 }}
          />
          <p>Selecciona los {brand === "envia" ? "guías" : "boletos"} que quieres facturar, al finalizar da click en siguiente</p>
          <img src={`${process.env.PUBLIC_URL}/assets/comofacturar/${brand}_paso1_2.jpg`} alt="example" />
        </div>
        <div className="step">
          <div className="step-head">
            <h3>Paso 2</h3>
            <p>Ingresa tus datos de facturación</p>
          </div>
          <img
            src={`${process.env.PUBLIC_URL}/assets/comofacturar/${brand}_paso2.jpg`}
            alt="example"
            style={{ marginTop: -10 }}
          />
        </div>
        <div className="step">
          <div className="step-head">
            <h3>Paso 3</h3>
            <p>Verifica tu factura, si todo está correcto, da clic en facturar. </p>
          </div>
          <img src={`${process.env.PUBLIC_URL}/assets/comofacturar/${brand}_paso3_1.jpg`} alt="example" />
          <img src={`${process.env.PUBLIC_URL}/assets/comofacturar/${brand}_paso3_2.jpg`} alt="example" />
        </div>
        <div className="step">
          <div className="step-head">
            <h3>Paso 4</h3>
            <p>Tu factura será enviada a tu correo electrónico en formato .xml y .pdf</p>
          </div>
          <img src={`${process.env.PUBLIC_URL}/assets/comofacturar/${brand}_paso4.jpg`} alt="example" />
        </div>
      </div>
    </div>
  )
}