import React, { useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { selectStep, setStep } from '../reducers/stepsSlice';
import { DatosFiscales } from './steps/datos-fiscales';
import { Generar } from './steps/generar';
import { IngresaDatos } from './steps/ingresa-tus-datos';
import { ModalEjemplos } from './modalEjemplos';
import { selectBrand } from '../reducers/brandingSlice';
import { IconDatosFiscales, IconGeneraFactura, IconIngresaBoleto } from './icons';

export function GenerarFactura({ showErrorModal, showModalConfirmar, setMensajes, setHandleConfirmar, setIsMostrarAceptar }) {
  /**
   * Brand/Theming 
   */
  const brand = useSelector(selectBrand);

  /**
   * Step Handle
   */
  const step = useSelector(selectStep);
  const dispatch = useDispatch();

  /**
   * Modal examples
   */
  const [ejemplos, setEjemplos] = useState(false);
  
  /**
   * Toggle examples modal
   */
  const showEjemplos = () => {
    setEjemplos(!ejemplos);
  }
  
  /**
   * Change current step
   * @param {*} step 
   */
  const handleStep = (step) => {
    window.scrollTo(0,0);
    dispatch(setStep(step));
  }

  return(
    <section className="generar-factura">
      <h2><b>Genera tu factura</b></h2>
      <h2>en 3 sencillos pasos</h2>
      {step === "ingresaDatos" && <h1><b>1. Ingresa tus</b> {brand === "envia" ? "guías" : "boletos"}</h1>}
      {step === "datosFiscales" && <h1><b>2. Ingresa tus </b> datos fiscales</h1>}
      {step === "generar" && <h1><b>3. Tu factura </b> está lista</h1>}
      
      <div className="wrapper">
        <div className="steps">
          <div className={step === 'ingresaDatos' || step === 'datosFiscales' || step === 'generar' ? 'step active' : 'step'}>
            <IconIngresaBoleto />
            <p>Ingresa tus {brand === "envia" ? "guías" : "boletos"}</p>
          </div>
          <div className={step === 'datosFiscales' || step === 'generar' ? 'step active' : 'step'}>
            <IconDatosFiscales />
            <p>Datos fiscales</p>
          </div>
          <div className={step === 'generar' ? 'step final' : 'step'}>
            <IconGeneraFactura />
            <p>Genera tu factura</p>
          </div>
        </div>
        {step === "ingresaDatos" && <IngresaDatos 
          showErrorModal={showErrorModal} 
          handleStep={handleStep} 
          showEjemplos={showEjemplos} 
          showModalConfirmar={showModalConfirmar} 
          setMensajes={setMensajes} 
          setHandleConfirmar={setHandleConfirmar}
          setIsMostrarAceptar={setIsMostrarAceptar}
        />}
        {step === "datosFiscales" && <DatosFiscales handleStep={handleStep} showErrorModal={showErrorModal} showModalConfirmar={showModalConfirmar} setMensajes={setMensajes} setHandleConfirmar={setHandleConfirmar} />}
        {step === "generar" && <Generar showEjemplos={showEjemplos}  />}
        <div className={ejemplos ? 'modal-wrapper active' : 'modal-wrapper'}>
          <ModalEjemplos showEjemplos={showEjemplos} />
        </div>
      </div>
    </section>
  )
}

export default GenerarFactura