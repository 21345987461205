import React from 'react';
import { useSelector } from 'react-redux';
import { selectBrand } from '../reducers/brandingSlice';

export function ModalEjemplos(props) {
  /**
   * Brand/Theming 
   */
  const brand = useSelector(selectBrand);
  return(
    <div 
      className="modal ejemplo"
      style={{ maxWidth: 800 }}
    >
      <div className="head">
        <h4>Ejemplo de {brand === "envia" ? "guías" : "boletos"}</h4>
        <span onClick={props.showEjemplos}>
          <img src={`${process.env.PUBLIC_URL}/assets/close.svg`} alt="close" />
        </span>
      </div>
      <div className="content1">
        <br />
        {brand === "eb" &&
          <>
          <div className="example">
            <h4>Estrella Blanca</h4>
            <img 
              src={`${process.env.PUBLIC_URL}/assets/ejemplos/EB.png`} 
              style={{ width: 700 }}
              alt="ejemplo" 
            />
          </div>
          <br />
          <div className="example">
            <h4>Chihuahuenses</h4>
            <img 
              src={`${process.env.PUBLIC_URL}/assets/ejemplos/Chihuahuense.png`} 
              style={{ width: 700 }}
              alt="ejemplo" 
            />
          </div>
          <br />
          <div className="example">
            <h4>Taquillas</h4>
            <img 
              src={`${process.env.PUBLIC_URL}/assets/ejemplos/Taquillas.png`} 
              style={{ width: 700 }}
              alt="ejemplo" 
            />
          </div>
          <br />
          <br />
          <div className="example">
            <h4>Oxxo</h4>
            <img 
              src={`${process.env.PUBLIC_URL}/assets/ejemplos/Oxxo.png`} 
              style={{ width: 800 }}
              alt="ejemplo" 
            />
          </div>
          </>
        }

        {brand === 'envia' && 
          <>
          <div className="example">
            <h3>Envia</h3>
            <img 
              src={`${process.env.PUBLIC_URL}/assets/ejemplos/envia.png`} 
              style={{ width: 700 }}
              alt="ejemplo" 
            />
          </div>
          </>
        }
      </div>
    </div>
  )
}