import React from 'react'

export const IconIngresaBoleto = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 239.5 239.5">
        <g className="layer">
            <title>Layer 1</title>
            <path d="m203.54,103.52l-4,-14a2.6,2.6 0 0 0 -3.37,-1.63a9.78,9.78 0 1 1 -5.41,-18.79a2.6,2.6 0 0 0 2,-3.18l-3.53,-12.12a14.17,14.17 0 0 0 -17.54,-9.71l-126,36.28a14.18,14.18 0 0 0 -9.69,17.54c2,5.41 4.51,16.07 3.62,21.78a2.5,2.5 0 0 0 2.38,2.51a9.83,9.83 0 0 1 9,6.67a9.62,9.62 0 0 1 -3.16,1.69a2.51,2.51 0 0 0 -1.66,3.08l1.82,5.92a9.71,9.71 0 0 1 -6,2.18a2.5,2.5 0 0 0 -2.44,2.51l0,14.56a14.19,14.19 0 0 0 14.19,14.19l31.72,0a2.51,2.51 0 0 0 0,-5l-31.72,0a9.17,9.17 0 0 1 -9.16,-9.16l0,-12.35a14.55,14.55 0 0 0 4.82,-1.84l0.86,3a14.18,14.18 0 0 0 17.54,9.7l116.05,-33.4a14.77,14.77 0 0 0 10.2,22.65l0,12.22a9.17,9.17 0 0 1 -9.17,9.16l-87.41,0a2.51,2.51 0 0 0 0,5l87.41,0a14.19,14.19 0 0 0 14.18,-14.18l0,-14.55a2.61,2.61 0 0 0 -2.8,-2.5a9.78,9.78 0 0 1 0,-19.56a2.59,2.59 0 0 0 2.8,-2.49l0,-1.42a14.19,14.19 0 0 0 4.47,-14.76zm-158.95,13.93l0,-6a9.85,9.85 0 0 1 9.71,12.65a14.92,14.92 0 0 0 -9.71,-6.65zm80.76,2.77l9,-2.59l4.08,14.17l-9,2.59l-4.08,-14.17zm72.59,-8.34a9.45,9.45 0 0 1 -5.48,4.37l-37.46,10.75l-0.28,-1a2.5,2.5 0 0 0 -4.81,1.39l0.28,1l-6.93,2l-4.77,-16.58a2.49,2.49 0 0 0 -3.1,-1.71l-13.81,4a2.53,2.53 0 0 0 -1.5,1.2a2.49,2.49 0 0 0 -0.21,1.91l4.77,16.58l-58.21,16.73a9.15,9.15 0 0 1 -11.34,-6.27l-3.41,-11.83a14.79,14.79 0 0 0 -8,-27.91l-2.88,-10a9.18,9.18 0 0 1 6.28,-11.34l83.73,-24.1l0.28,1a2.52,2.52 0 0 0 2.41,1.81a2.35,2.35 0 0 0 0.69,-0.1a2.51,2.51 0 0 0 1.72,-3.1l-0.28,-1l37.47,-10.79a9.17,9.17 0 0 1 11.34,6.27l2.85,9.88a14.79,14.79 0 0 0 8.09,28.11l3.38,11.74a9.16,9.16 0 0 1 -0.79,7l-0.03,-0.01z" id="svg_1" />
            <path d="m141,78.61l-1.84,-6.39a2.5,2.5 0 0 0 -4.81,1.39l1.88,6.39a2.61,2.61 0 0 0 3.1,1.71a2.5,2.5 0 0 0 1.71,-3.1l-0.04,0z" id="svg_2" />
            <path d="m146.21,96.56l-1.84,-6.39a2.51,2.51 0 0 0 -4.82,1.39l1.84,6.44a2.62,2.62 0 0 0 3.1,1.71a2.51,2.51 0 0 0 1.72,-3.15z" id="svg_3" />
            <path d="m149.54,108.12a2.51,2.51 0 0 0 -4.82,1.39l1.84,6.39a2.62,2.62 0 0 0 3.1,1.71a2.51,2.51 0 0 0 1.72,-3.1l-1.84,-6.39z" id="svg_4" />
            <path d="m176.26,68.54a2.49,2.49 0 0 0 0.21,-1.91l-2.8,-9.74a2.5,2.5 0 0 0 -3.1,-1.71l-25.2,7.25a2.51,2.51 0 0 0 -1.72,3.1l2.81,9.74c0.51,1 1,2.11 3.1,1.72l25.2,-7.26a2.48,2.48 0 0 0 1.5,-1.19zm-25.68,2.94l-1.42,-4.93l20.39,-5.86l1.45,4.92l-20.42,5.87z" id="svg_5" />
            <path d="m172.32,106.7l13.46,-3.88a2.5,2.5 0 1 0 -1.39,-4.82l-13.46,3.87a2.51,2.51 0 0 0 0.69,4.92a2.47,2.47 0 0 0 0.7,-0.1l0,0.01z" id="svg_6" />
            <path d="m186.88,106.64l-13.47,3.88a2.5,2.5 0 0 0 0.7,4.91a2.4,2.4 0 0 0 0.69,-0.1l13.46,-3.87a2.51,2.51 0 1 0 -1.38,-4.82z" id="svg_7" />
            <path d="m180,162.57a2.51,2.51 0 0 0 2.5,-2.51l0,-9.7a2.5,2.5 0 0 0 -2.5,-2.5l-27.63,0a2.5,2.5 0 0 0 -2.5,2.5l0,9.7a2.51,2.51 0 0 0 2.5,2.51l27.63,0zm-25.16,-9.7l22.65,0l0,4.69l-22.61,0l-0.04,-4.69z" id="svg_8" />
            <path d="m140.11,157.21a2.51,2.51 0 0 0 0,-5l-26.64,0a2.51,2.51 0 0 0 0,5l26.64,0z" id="svg_9" />
            <path d="m105.27,98.26a4.42,4.42 0 0 0 5.53,2.61a4.13,4.13 0 0 0 3.15,-3.1l3.81,-1a9.12,9.12 0 0 1 -2.07,4.75a8,8 0 0 1 -4.06,2.56a8.52,8.52 0 0 1 -4.53,-16.42a8.19,8.19 0 0 1 6.42,0.79a8.66,8.66 0 0 1 4.21,6.45l-12.46,3.36zm8.06,-5.26a4.42,4.42 0 0 0 -5.33,-2.26a4.33,4.33 0 0 0 -3.42,4.68l8.75,-2.42z" id="svg_10" />
            <path d="m118.18,85.11l3.49,-1l0.56,2a4.59,4.59 0 0 1 3.6,-3.68l1,3.72l-0.17,0c-2.59,0.7 -3.59,2.28 -2.86,5l2.41,8.82l-3.69,1l-4.34,-15.86z" id="svg_11" />
            <path d="m99.2,89.67l-11,3a1.62,1.62 0 0 0 -1.1,1.07l-0.68,1.79a0.16,0.16 0 0 0 0.19,0.22l9.34,-2.5a0.66,0.66 0 0 0 0.28,-0.16l3.16,-3.09a0.21,0.21 0 0 0 0,-0.29a0.2,0.2 0 0 0 -0.19,-0.04z" id="svg_12" />
            <path d="m85.82,102.44c-1.41,0.39 -4.4,3.85 -4.4,3.85a0.5,0.5 0 0 1 -0.29,0.12a0.46,0.46 0 0 1 -0.42,-0.24a0.43,0.43 0 0 1 0,-0.31l0.81,-3.93a1.72,1.72 0 0 0 0,-0.32a0.91,0.91 0 0 0 0,-0.23a0.74,0.74 0 0 0 -0.25,-0.39a2.11,2.11 0 0 0 -0.3,-0.21l-3.74,-2.21a0.43,0.43 0 0 1 -0.23,-0.51a0.43,0.43 0 0 1 0.31,-0.26l4.15,-1.13a2.41,2.41 0 0 0 1.51,-1.24l0.15,-0.34c0.1,-0.25 0.83,-2.22 0.91,-2.5a13.48,13.48 0 0 0 1,-4c0,-0.8 -1.26,-2.42 -1.42,-2.69a0.61,0.61 0 0 0 -0.69,-0.32a0.77,0.77 0 0 0 -0.56,0.62c-0.08,0.29 -2.55,8 -2.55,8a1.62,1.62 0 0 1 -1.09,1l-9.72,2.68c-0.6,0.16 -0.83,0.54 -0.73,0.9l0,0a0.94,0.94 0 0 0 0,0.09a1.35,1.35 0 0 0 0.5,0.52c2.49,1.59 7.34,4.33 7.57,4.49l0.23,0.16a0.79,0.79 0 0 1 0.31,0.44a1.39,1.39 0 0 1 0,0.59c-0.4,2.16 -1.57,7.67 -1.61,7.92a1.07,1.07 0 0 0 0,0.4l0,0a0.46,0.46 0 0 0 0.56,0.32l0.06,0a1.05,1.05 0 0 0 0.3,-0.17c0.07,0 5.32,-4.65 6.79,-5.94c0.68,-0.59 0.92,-0.67 1.57,-0.53s6.08,1.48 7.53,1.85a2.23,2.23 0 0 0 1,0.12l0,0a0.59,0.59 0 0 0 0.41,-0.73l0,-0.14s-1.48,-4.25 -1.55,-4.44a0.58,0.58 0 0 0 -0.06,-0.17c-1.2,-0.98 -4.58,-1.56 -6.06,-1.12z" id="svg_13" />
            <path d="m86.25,97.54l6.89,-1.89a0.4,0.4 0 0 1 0.4,0.68l-2.83,2.67a0.45,0.45 0 0 1 -0.19,0.11l-4.32,1.18a0.49,0.49 0 0 1 -0.27,0a0.75,0.75 0 0 1 -0.58,-0.79a6.76,6.76 0 0 1 0.5,-1.57a0.38,0.38 0 0 1 0.12,-0.16l0.11,-0.09a0.34,0.34 0 0 1 0.17,-0.14z" id="svg_14" />
            <path d="m128.3,81.9a0.8,0.8 0 1 0 0.42,1.54a0.8,0.8 0 1 0 -0.42,-1.54zm0.38,1.39a0.65,0.65 0 0 1 -0.79,-0.45a0.64,0.64 0 0 1 0.45,-0.78a0.62,0.62 0 0 1 0.78,0.44a0.63,0.63 0 0 1 -0.44,0.79z" id="svg_15" />
            <path d="m128.79,82.39c0,-0.18 -0.18,-0.23 -0.38,-0.17l-0.34,0.09l0.24,0.9l0.13,0l-0.1,-0.39l0.15,0l0.34,0.32l0.15,0l-0.36,-0.32a0.24,0.24 0 0 0 0.17,-0.43zm-0.48,0.28l-0.08,-0.28l0.19,0c0.09,0 0.2,0 0.23,0.08s-0.07,0.17 -0.18,0.2l-0.16,0z" id="svg_16" />
            <path d="m119.75,239.5a119.75,119.75 0 1 1 119.75,-119.75a119.89,119.89 0 0 1 -119.75,119.75zm0,-232.87a113.12,113.12 0 1 0 113.12,113.12a113.24,113.24 0 0 0 -113.12,-113.12z" id="svg_17" />
            <rect fill="#FF0000" height="0" id="svg_18" stroke="#000000" width="0" x="67.35" y="88.5" />
            <rect fill="#ffffff" height="29.75" id="svg_19" transform="rotate(-18.1044 101.021 93.4634)" width="65.23" x="68.41" y="78.59" />
        </g>
    </svg>
}

export const IconDatosFiscales = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 239.5 239.5">
        <path d="M119.75,239.5A119.75,119.75,0,1,1,239.5,119.75,119.89,119.89,0,0,1,119.75,239.5Zm0-232.87A113.12,113.12,0,1,0,232.87,119.75,113.24,113.24,0,0,0,119.75,6.63Z" />
        <path d="M179.78,127.75A1.77,1.77,0,0,1,178,126v-5.24a1.77,1.77,0,1,1,3.53,0V126A1.76,1.76,0,0,1,179.78,127.75Z" />
        <path d="M176.25,181.54h-113A5.29,5.29,0,0,1,58,176.25v-113A5.29,5.29,0,0,1,63.25,58h113a5.29,5.29,0,0,1,5.29,5.29v45.13a1.77,1.77,0,1,1-3.53,0V63.25a1.76,1.76,0,0,0-1.76-1.76h-113a1.76,1.76,0,0,0-1.76,1.76v113A1.76,1.76,0,0,0,63.25,178h113a1.76,1.76,0,0,0,1.76-1.76V155.84a1.77,1.77,0,1,1,3.53,0v20.41A5.29,5.29,0,0,1,176.25,181.54Z" />
        <path d="M179.78,145.25a1.77,1.77,0,0,1-1.77-1.77v-5.14a1.77,1.77,0,0,1,3.53,0v5.14A1.77,1.77,0,0,1,179.78,145.25Z" />
        <path d="M179.78,88H59.72a1.77,1.77,0,0,1,0-3.53H179.78a1.77,1.77,0,0,1,0,3.53Z" />
        <path d="M113.61,74.73h-43a1.77,1.77,0,1,1,0-3.53h43a1.77,1.77,0,0,1,0,3.53Z" />
        <path d="M167.09,74.73h-4.65a1.77,1.77,0,1,1,0-3.53h4.65a1.77,1.77,0,0,1,0,3.53Z" />
        <path d="M150,74.73h-4.65a1.77,1.77,0,1,1,0-3.53H150a1.77,1.77,0,1,1,0,3.53Z" />
        <path d="M133,74.73h-4.65a1.77,1.77,0,0,1,0-3.53H133a1.77,1.77,0,1,1,0,3.53Z" />
        <path d="M93.71,146.23a22.51,22.51,0,1,1,22.51-22.51A22.51,22.51,0,0,1,93.71,146.23Zm0-41.49a19,19,0,1,0,19,19A19,19,0,0,0,93.71,104.74Z" />
        <path d="M110.58,137.58a1.75,1.75,0,0,1-1.44-.74,18.93,18.93,0,0,0-30.86,0,1.76,1.76,0,0,1-2.88-2,22.46,22.46,0,0,1,36.62,0,1.76,1.76,0,0,1-1.44,2.78Z" />
        <path d="M93.71,128.88a9,9,0,1,1,9-9h0A9,9,0,0,1,93.71,128.88Zm0-14.5a5.48,5.48,0,1,0,5.48,5.48h0A5.48,5.48,0,0,0,93.71,114.38Z" />
        <path d="M169.19,109.16H123.28a1.77,1.77,0,0,1,0-3.53h45.91a1.77,1.77,0,0,1,0,3.53Z" />
        <path d="M169.19,127.4H126.81a1.77,1.77,0,0,1,0-3.53h42.38a1.77,1.77,0,0,1,0,3.53Z" />
        <path d="M169.19,145.65H119.75a1.77,1.77,0,0,1,0-3.54h49.44a1.77,1.77,0,0,1,0,3.54Z" />
        <path d="M169.19,163.89H73.85a1.77,1.77,0,1,1,0-3.53h95.34a1.77,1.77,0,0,1,0,3.53Z" />
    </svg>
}

export const IconGeneraFactura = () => {
    return <svg xmlns="http://www.w3.org/2000/svg" viewBox="0 0 241.61 241.61">
        <path d="M120.8,241.61A120.81,120.81,0,1,1,241.61,120.8,120.94,120.94,0,0,1,120.8,241.61Zm0-234.93A114.12,114.12,0,1,0,234.92,120.8,114.26,114.26,0,0,0,120.8,6.68Z" />
        <path d="M166.28,55.67H88.74a8.06,8.06,0,0,0-8,8v18a35.17,35.17,0,0,0,0,67.64v31a8.06,8.06,0,0,0,8,8h63.37a2.79,2.79,0,0,0,2-.83l19.39-19.38a2.87,2.87,0,0,0,.83-2V63.72A8.06,8.06,0,0,0,166.28,55.67ZM88.74,61.34h77.54a2.39,2.39,0,0,1,2.38,2.38v8.55H86.36V63.72A2.39,2.39,0,0,1,88.74,61.34ZM60.85,115.55a29.51,29.51,0,1,1,29.51,29.5A29.54,29.54,0,0,1,60.85,115.55Zm25.51,64.81V150.49a35.08,35.08,0,0,0,30.5-11.85h17.37a2.84,2.84,0,1,0,0-5.67H120.9a34.7,34.7,0,0,0,4.49-14.37h8.84a2.84,2.84,0,1,0,0-5.67h-8.8a34.77,34.77,0,0,0-4.29-14.37h13.09a2.84,2.84,0,1,0,0-5.67h-17A35.09,35.09,0,0,0,86.36,80.61V77.94h82.3v85.41H157.32a8.06,8.06,0,0,0-8,8v11.35H88.74A2.39,2.39,0,0,1,86.36,180.36Zm68.58-1.63v-7.34a2.38,2.38,0,0,1,2.38-2.37h7.33Z" />
        <path d="M145.37,98.56h9.41a2.84,2.84,0,1,0,0-5.67h-9.41a2.84,2.84,0,1,0,0,5.67Z" />
        <path d="M145.37,118.6h9.41a2.84,2.84,0,1,0,0-5.67h-9.41a2.84,2.84,0,1,0,0,5.67Z" />
        <path d="M145.37,138.64h9.41a2.84,2.84,0,1,0,0-5.67h-9.41a2.84,2.84,0,1,0,0,5.67Z" />
        <path d="M154.78,158.68a2.84,2.84,0,1,0,0-5.67H128.27a2.84,2.84,0,1,0,0,5.67Z" />
        <path d="M112.36,101.25l-3.45-3.46a4.95,4.95,0,0,0-7,0L83.79,115.93l-5-5a4.91,4.91,0,0,0-7,0l-3.46,3.46a4.94,4.94,0,0,0,0,7l12,12a4.89,4.89,0,0,0,3.48,1.44h0a4.9,4.9,0,0,0,3.49-1.44l25.08-25.09A4.92,4.92,0,0,0,112.36,101.25ZM83.79,128.78,72.88,117.86l2.41-2.41,6.5,6.49a2.83,2.83,0,0,0,4,0l19.62-19.62,2.41,2.42Z" />
    </svg>
}

export const IconPrint = ({ color }) => {
    return <svg
        fill={color || "#005db4"}
        viewBox="0 0 239.51 239.51"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <path className="cls-1" d="M119.76,239.51A119.76,119.76,0,1,1,239.51,119.76,119.89,119.89,0,0,1,119.76,239.51Zm0-232.88A113.13,113.13,0,1,0,232.89,119.76,113.26,113.26,0,0,0,119.76,6.63Z" />
                <path className="cls-1" d="M146.54,78.1a2.19,2.19,0,0,0,2.2-2.19h0V63a2.2,2.2,0,1,0-4.39,0V75.91a2.19,2.19,0,0,0,2.19,2.19Z" />
                <path className="cls-1" d="M184.43,95.39H171.94V64.81a2.19,2.19,0,0,0-2.19-2.19H156.27V50.37a2.19,2.19,0,0,0-2.19-2.19H85.44a2.19,2.19,0,0,0-2.2,2.19V62.62H69.76a2.19,2.19,0,0,0-2.19,2.19V95.39H55.08a9.75,9.75,0,0,0-9.73,9.73V165a9.75,9.75,0,0,0,9.73,9.73H83.23v20a2.2,2.2,0,0,0,2.2,2.2h68.65a2.2,2.2,0,0,0,2.2-2.2v-20h28.15a9.75,9.75,0,0,0,9.73-9.73V105.12A9.75,9.75,0,0,0,184.43,95.39ZM167.55,67V95.39H156.27V67ZM87.63,52.57h64.25V95.39H87.63ZM72,67H83.24V95.39H72Zm0,103.36V148H83.23v22.36Zm15.67,22.22V148h64.25v44.58Zm68.65-22.22V148h11.27v22.36ZM189.76,165a5.33,5.33,0,0,1-5.33,5.33H171.94V145.81a2.2,2.2,0,0,0-2.19-2.2h-100a2.2,2.2,0,0,0-2.19,2.2v24.56H55.08A5.33,5.33,0,0,1,49.75,165V105.12a5.33,5.33,0,0,1,5.33-5.33H184.43a5.33,5.33,0,0,1,5.33,5.33Z" />
                <path className="cls-1" d="M90,110.46H58.85a2.2,2.2,0,1,0,0,4.39H90a2.2,2.2,0,0,0,0-4.39Z" />
                <path className="cls-1" d="M138.59,154.29H100.92a2.2,2.2,0,1,0,0,4.39h37.67a2.2,2.2,0,1,0,0-4.39Z" />
                <path className="cls-1" d="M138.59,165.59H100.92a2.2,2.2,0,1,0,0,4.39h37.67a2.2,2.2,0,1,0,0-4.39Z" />
                <path className="cls-1" d="M138.59,176.89H100.92a2.2,2.2,0,1,0,0,4.4h37.67a2.2,2.2,0,0,0,0-4.4Z" />
                <path className="cls-1" d="M176.89,106.69a6,6,0,1,0,6,6h0A6,6,0,0,0,176.89,106.69Zm0,7.53a1.57,1.57,0,1,1,1.57-1.57A1.57,1.57,0,0,1,176.89,114.22Z" />
                <path className="cls-1" d="M162,106.69a6,6,0,1,0,6,6h0A6,6,0,0,0,162,106.69Zm0,7.53a1.57,1.57,0,1,1,1.57-1.57A1.57,1.57,0,0,1,162,114.22Z" />
                <path className="cls-1" d="M147.07,106.69a6,6,0,1,0,6,6A6,6,0,0,0,147.07,106.69Zm0,7.53a1.57,1.57,0,1,1,1.57-1.57h0A1.58,1.58,0,0,1,147.07,114.22Z" />
            </g>
        </g>
    </svg>
}

export const IconDescargar = ({ color }) => {
    return <svg
        fill={color || "#005db4"}
        viewBox="0 0 239.51 239.51"
        xmlns="http://www.w3.org/2000/svg"
    >
        <g id="Capa_2" data-name="Capa 2">
            <g id="Capa_1-2" data-name="Capa 1">
                <path className="cls-1" d="M119.76,239.51A119.76,119.76,0,1,1,239.51,119.76,119.89,119.89,0,0,1,119.76,239.51Zm0-232.88A113.13,113.13,0,1,0,232.89,119.76,113.26,113.26,0,0,0,119.76,6.63Z" />
                <g id="surface1">
                    <path className="cls-1" d="M143.65,47.84a3.56,3.56,0,0,0-2.54-1.13h-57a19.28,19.28,0,0,0-19.2,19.16V173.64a19.28,19.28,0,0,0,19.2,19.16h77.35a19.29,19.29,0,0,0,19.2-19.16V88.07a3.79,3.79,0,0,0-1-2.47Zm1,11.33,24.1,25.3H153.11a8.41,8.41,0,0,1-8.44-8.4Zm16.8,126.57H84.12A12.22,12.22,0,0,1,72,173.64V65.87a12.22,12.22,0,0,1,12.14-12.1h53.49v22.3a15.45,15.45,0,0,0,15.5,15.46h20.5v82.11A12.2,12.2,0,0,1,161.47,185.74Z" />
                    <path className="cls-1" d="M151.84,161.4H93.75a3.53,3.53,0,1,0,0,7h58.12a3.53,3.53,0,1,0,0-7Z" />
                    <path className="cls-1" d="M120.22,148.73a3.5,3.5,0,0,0,5.15,0l20.68-22.2a3.52,3.52,0,0,0-5.15-4.8l-14.58,15.63V98.83a3.53,3.53,0,1,0-7,0v38.53l-14.54-15.63a3.52,3.52,0,0,0-5.15,4.8Z" />
                </g>
            </g>
        </g>
    </svg>
}